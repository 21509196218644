<template>
  <div
    class="quick-view__gallecyWrap"
    :class="{ 'not-padding-left': quickViewConfig.hideThumb }"
  >
    <!-- 左边小图 -->
    <div
      v-if="!quickViewConfig.hideThumb"
      class="quick-view__thumbs"
    >
      <div class="quick-view__thumbs-wrap">
        <template
          v-for="(item, index) in gallery"
          :key="index"
        >
          <div
            class="quick-view__thumbs-item"
            :class="{ active: activeIndex == index }"
            @click="clickThumbnail(index)"
          >
            <CropImageContainer
              :img-src="transformImg({ img: dprInsert(item.thumbnail, 'mini', 'quickView') })"
              :fixed-ratio="fixedRatio"
              :transform-img-props="{
                imgClassName: 'lazyload',
                isFirstPage: false,
                loadImg: LAZY_IMG,
              }"
            />
          </div>
        </template>
      </div>
    </div>

    <div
      ref="quick-view__main-swiper"
      class="quick-view__main swiper-container"
      tabindex="0"
      :aria-label="language.SHEIN_KEY_PC_15379"
    >
      <!-- 主图 -->
      <swiper-container
        ref="newSwiper"
        init="false"
        :pagination="true"
        class="s-swiper-wrapper"
      >
        <swiper-slide
          v-for="(item, index) in gallery"
          :key="index"
          class="s-swiper-slide quick-view__main-item"
        >
          <!-- <img
            v-if="(index == 0 && mainLoading) || index != 0"
            :src="LAZY_IMG"
            :data-src="transformImg({ img: dprInsert(item.origin_image, '', 'quickView') })"
            :alt="`${productInfo.goods_name}`"
          /> -->
          <CropImageContainer
            v-if="(index == 0 && mainLoading) || index != 0"
            :img-src="transformImg({ img: dprInsert(item.origin_image, '', 'quickView') })"
            :fixed-ratio="fixedRatio"
            :transform-img-props="{
              isFirstPage: false,
              loadImg: LAZY_IMG,
            }"
          />
        </swiper-slide>
      </swiper-container>
      <!-- 切换颜色loading时的占位图 -->
      <CropImageContainer
        v-show="queryLoading"
        :img-src="transformImg({ img: dprInsert(gallery[0]?.origin_image, '', 'quickView') })"
        :fixed-ratio="fixedRatio"
        :transform-img-props="{
          isFirstPage: false,
          loadImg: LAZY_IMG,
        }"
      />
      <!-- pre -->
      <div
        ref="swiperPre"
        class="quick-view__main-pre quick-view__main-arrow"
      >
        <Icon
          name="sui_icon_more_left_18px"
          size="16"
          class="arrow-icon"
        />
      </div>
      <!-- next -->
      <div
        ref="swiperNext"
        class="quick-view__main-next quick-view__main-arrow"
      >
        <Icon
          name="sui_icon_more_right_18px"
          size="16"
          class="arrow-icon"
        />
      </div>
      <!-- 小点展示 -->
      <div
        ref="swiperPage"
        class="quick-view__main-bullets"
        :class="{ 'quick-view__main-split': showBestDealBelt }"
      ></div>

      <!-- 统一聚合腰带 [保留款 - 跟价款 - x天最低价] -->
      <BestDealBelt
        v-if="showBestDealBelt"
        :right-text="bestDealConfig.rightText"
        :content-text="bestDealConfig.text"
      />

      <!-- 人气氛围信息滚动展示框 -->
      <QuickAtmosphereFlow
        v-if="atomFlowConfig.pageShow"
        :show="atomFlowConfig.pageShow"
        :analysis-info="atomFlowConfig.analysis"
        :product-info="productInfo"
        :config="atomFlowConfig"
        location="quickaddtobag"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { transformImg } from '@shein/common-function'
import BestDealBelt from 'public/src/pages/goods_detail_v2/components/Belt/BestDealBelt.vue'
import dprInsertMixin from '../../../goods_detail_v2/utils/dprInsertMixins'
import QuickAtmosphereFlow from './QuickAtmosphereFlow.vue'
import { CropImageContainer } from 'public/src/pages/components/CropImageContainer/index.js'
import { Icon } from '@shein-aidc/icon-vue3'
import { register } from 'swiper/element'
import { Pagination } from 'swiper/modules'
import { Navigation } from 'swiper/modules'
const { PUBLIC_CDN } = gbCommonInfo
import { patchImgUrlSuffix } from 'public/src/pages/goods_detail_v2/utils/common.js'
// 只在客户端环境中注册 swiper
typeof window !== 'undefined' && register()

export default {
  name: 'QuickViewMainSwiper',
  components: { QuickAtmosphereFlow, CropImageContainer, BestDealBelt, Icon },
  mixins: [dprInsertMixin],
  props: {
    goodsData: {
      type: Object,
      default() {
        return {}
      },
    },
    quickViewConfig: {
      type: Object,
      default() {
        return {}
      },
    },
    bestDealConfig: {
      type: Object,
      default: () => ({})
    },
    fixedRatio: {
      type: String,
      default: '3-4'
    },
    queryLoading: {
      type: Boolean,
      default: false
    },
    rootStatus: {
      type: Object,
      default() {
        return {}
      },
    },
    showSheglamWatermark: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      LAZY_IMG,
      activeIndex: 0,
      gallerySwiper: null,
      mainLoading: false,
    }
  },
  computed: {
    productInfo() {
      return this.goodsData.detail || {}
    },
    gallery() {
      let gallery = []
      if (this.goodsData?.goods_imgs) {
        const goodsImgs = cloneDeep(this.goodsData.goods_imgs)
        const detailImage = goodsImgs.detail_image || []
        const { tsp = {}, screenAbtConfig = {} } = this.goodsData
        const { Mastertest = {} } = screenAbtConfig
        const WATERMARK_TSP_TAG_ID = '100000112' // 需要加水印的商品TSP 标签
        const IS_WATER_GOODS = !!tsp?.[WATERMARK_TSP_TAG_ID] || false
        const IS_MASTERT_ABT = Mastertest?.p?.Mastertest === 'SHOW'
        const shouldWatermarkHidden = gbCommonInfo.DETAIL_SECOND_CONFIG?.DETAIL_WATERMARK_SWITCH === 'off'
        if(IS_WATER_GOODS && IS_MASTERT_ABT && !shouldWatermarkHidden) {
          detailImage.forEach(item => {
            let imgSrc = item?.origin_image || ''
            if(imgSrc.indexOf('_wk_shein') === -1 && imgSrc.indexOf('images3') > -1) {
              const suffix = imgSrc.split('.')?.pop() || ''
              item.origin_image = suffix ? imgSrc.replace(`.${suffix}`, `_wk_shein.${suffix}`) : imgSrc
            }
          })
        }

        const { showSheglamWatermark } = this
        // 除首图/视频/GIF外/评论图、尺码图、属性图的每一张主图都需展示水印
        if (showSheglamWatermark) {
          const _START_INDEX = 1 // 从第二张开始
          for (let i = _START_INDEX; i < detailImage.length; i++) {
            const item = detailImage[i]
            if (!item?.origin_image || item.isMoreDetail || item.isVideo || item.isOutfit) continue
            item.origin_image = patchImgUrlSuffix(item.origin_image, '_wk_sheglam')
          }
        }

        gallery = detailImage
      }
      return gallery
    },
    atomFlowConfig() {
      const { hotnews = {} } = this.goodsData?.screenAbtConfig || {}
      const { toastcolor, quickaddtobag } = hotnews?.param || {}
      const pageShow = quickaddtobag == 'on' && !this.quickViewConfig.forbidAtmosphereFlow
      return { 
        pageShow,
        toastcolor,
        analysis: {
          // abtest: abtservice.getUserAbtResultForAnalysis({ abtInfo: { 'hotnews': hotnews } }).sa
        }
      }
    },
    showBestDealBelt() {
      const { text, type } = this.bestDealConfig
      return (text && !!~type.indexOf('Belt'))
    },
    language() {
      return this.$root.language || {}
    }
  },
  watch: {
    gallery: {
      deep: true,
      handler(data) {
        if (!data.length) return
        this.mainLoading = false
        this.$nextTick(() => {
          document.querySelectorAll('.quick-view__gallecyWrap .lazyload')?.forEach(img => {
            img.removeAttribute('loaded')
            img.setAttribute('src', this.LAZY_IMG)
          })
          this.mainLoading = true
          this.$nextTick(() => {
            this.initSwiper()
            if (typeof GB_SHEIN_lazyLoadInstance != 'undefined') {
              GB_SHEIN_lazyLoadInstance.update()
            }
          })
        })
      },

      immediate: true,
    },
    'rootStatus.popupModal'(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initSwiper()
        })
      }
    },
  },
  methods: {
    initSwiper() {
      const swiperEl = this.$refs.newSwiper
      if (!swiperEl) return

      const mainSwiperRef = this.$refs['quick-view__main-swiper']

      /**
       * 提前预加载下一张图片和前一张图片
       */
      const preLoadNextPre = () => {
        const swiperNext = mainSwiperRef.querySelector('.swiper-slide-next')
        const swiperActive = mainSwiperRef.querySelector('.swiper-slide-active')
        const swiperPre = mainSwiperRef.querySelector('.swiper-slide-prev')
        ;[swiperActive, swiperNext, swiperPre].forEach((el) => {
          const img = el?.querySelector('img')
          if (!img?.getAttribute('loaded')) {
            // 1. 提前load
            const imgUrl = img?.getAttribute('data-src')
            img?.setAttribute('src', imgUrl)
            // // 2. 标记已经load
            img?.setAttribute('loaded', true)
          }
        })
      }
      const swiperParams = {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        observer: true,
        observeParents: true,
        modules: [Pagination, Navigation],
        navigation: {
          prevEl: this.$refs['swiperPre'],
          nextEl: this.$refs['swiperNext'],
        },
        pagination: {
          el: this.$refs['swiperPage'], //'.quick-view__main-bullets',
          type: 'bullets',
        },
        injectStylesUrls: [
          PUBLIC_CDN + '/she_dist/libs/swiper/modules/pagination-element.min.css',
        ],
        on: {
          init: preLoadNextPre,
          transitionEnd: preLoadNextPre,
          slideChangeTransitionEnd: (swiper) => {
            this.activeIndex = swiper.realIndex
          }
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.gallerySwiper = swiperEl.swiper
      swiperEl.swiper.loopCreate()
      setTimeout(()=>{
        this.activeIndex = 0
        this.gallerySwiper?.slideToLoop(0, 0)
      }, 100)
    },
    // 点击小图
    clickThumbnail(index) {
      this.gallerySwiper && this.gallerySwiper.slideToLoop(index, 300)
    },
    transformImg,
  },
}
</script>
