<template>
  <div
    class="app-discount"
    @click.stop
    @mouseenter="showAppQRCode(true)"
    @mouseleave="showAppQRCode(false)"
  >
    <div>
      <Icon
        name="sui_icon_phone_16px"
        size="16px"
      />
      <span>{{ extraOrOriginDiscount }}</span>
    </div>
    <s-popover
      v-model="isShowAppQRCode"
      placemen="bottom-end"
      :prop-style="{ maxWidth: 'none' }"
      :hide-close-icon="true"
      :append-to-body="false"
    >
      <div class="app-discount-popover">
        <section class="app-discount-popover__goods-info">
          <img
            :src="thumbImg"
            :alt="goodsName"
          />
          <div class="app-discount-popover__goods-info_right">
            <div class="app-discount-popover__goods-info_top">
              <span
                class="app-discount-popover__goods-info_price"
                :class="{ 'app-discount-popover__goods-info_discountPrice': retailPrice }"
                v-html="salePrice"
              ></span>
              <span
                class="app-discount-popover__goods-info_retailPrice"
                v-html="retailPrice"
              >
              </span>
              <span
                v-if="promotionLabel"
                class="app-discount-popover__goods-info_discount"
              >{{ promotionLabel }}</span>
            </div>
            <div class="app-discount-popover__goods-info_appDiscount">
              {{ extraOrOriginDiscountPop }} | {{ language.SHEIN_KEY_PC_18000 }}
            </div>
          </div>
        </section>
        <section class="app-discount-popover__qr-code">
          <div
            id="qrcode"
            class="app-discount-popover__qr-code_ctn"
          ></div>
          <div class="app-discount-popover__qr-code_desc">
            {{ language.SHEIN_KEY_PC_18009 }}
          </div>
        </section>
      </div>
      <template #reference>
        <Icon
          v-if="isShowAppQRCode"
          name="sui_icon_more_up_18px"
          size="16px"
          class="arrow"
        />
        <Icon
          v-else
          name="sui_icon_more_down_18px"
          size="16px"
          class="arrow"
        />
      </template>
    </s-popover>
  </div>
</template>

<script>
import deviceFingerprint from 'public/src/pages/common/deviceFingerprint'
import md5 from 'md5'
import i18n from 'public/src/pages/common/biz_helper/i18n'
import { template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
const JOURNEY_NAME = 'app-discount-20210113'
let QRCode = {}
// ssr
if (typeof window !== 'undefined') {
  import('../../js/qrcode').then(res => {
    QRCode = res.default
  })
}

export default {
  name: 'AppExclusiveDiscount',
  components: { Icon },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    thumbImg: {
      type: String,
      default: '',
    },
    goodsName: {
      type: String,
      default: '',
    },
    goodsId: {
      type: [String, Number],
      default: '',
    },
    salePrice: {
      type: String,
      default: '',
    },
    retailPrice: {
      type: String,
      default: '',
    },
    promotionLabel: {
      type: String,
      default: ''
    },
    webClient: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      language: {},
      isShowAppQRCode: false,
      qrCode: null,
      req: null,
      cacheLink: '',
      cacheDeepLink: '',
      cacheGoodsId: '',
    }
  },
  computed: {
    discount() {
      return ((1 - this.data.discountValue) * 100).toFixed(0)
    },
    // 原价折
    originDiscount() {
      return this.data.ruleType == 'discount'
    },
    // 折上折
    extraDiscount() {
      return this.data.ruleType == 'sale_discount'
    },
    extraOrOriginDiscount() {
      return this.originDiscount
        ? template(this.discount, this.language.SHEIN_KEY_PC_18005)
        : this.extraDiscount
          ? template(this.discount, this.language.SHEIN_KEY_PC_18006)
          : ''
    },
    extraOrOriginDiscountPop() {
      return this.originDiscount
        ? template(this.discount, this.language.SHEIN_KEY_PC_18007)
        : this.extraDiscount
          ? template(this.discount, this.language.SHEIN_KEY_PC_18008)
          : ''
    },
    isShein() {
      return this.webClient == 'shein'
    },
  },
  watch: {
    isShowAppQRCode(show) {
      if (show) {
        this.$nextTick(() => {
          setTimeout(async () => {
            if (
              !this.cacheLink ||
              !this.cacheDeepLink ||
              +this.goodsId !== +this.cacheGoodsId
            ) {
              const { link, deepLink } = await this.dealLink()
              this.cacheLink = link
              this.cacheDeepLink = deepLink
              this.cacheGoodsId = this.goodsId
            }

            // 埋点
            this.clickAnalysis(this.cacheLink, this.cacheDeepLink)

            if (this.qrCode) {
              this.qrCode.makeCode(this.cacheLink)
              return
            }
            this.qrCode = new QRCode('qrcode', {
              // qrcode为id
              text: this.cacheLink,
              width: 230,
              height: 230,
            })
          }, 50)
        })
      }
    },
  },
  async mounted() {
    this.language = await i18n.loadByQueue('app_exclusive_discount')
    this.exposeAnalysis()
  },
  methods: {
    template,
    showAppQRCode(show) {
      this.isShowAppQRCode = show
    },
    async dealLink() {
      const shortLinkHost = this.isShein ? 'shein.top' : 'fun.romwe.com'
      const randomText = md5(UserInfoManager.get({ key: 'oest' }) + Date.now())
      const shortLink = `http://${shortLinkHost}/pc-app-${randomText}`
      const deepLink = `${this.webClient}link://applink/goods?data=${encodeURIComponent(
        JSON.stringify({ goods_id: this.goodsId })
      )}`
      // 中转页域名，灰度和生产环境使用location.origin, 因为可以重定向到移动端，本地和测试需要写死host
      const transitPageLink = `${location.origin}/transit?deeplink=${deepLink}&journey_name=${JOURNEY_NAME}` // 灰度、生产
      // const transitPageLink = `http://local.shein.com:3888/transit?deeplink=${encodeURIComponent(deepLink)}&journey_name=${JOURNEY_NAME}` // 本地
      // const transitPageLink = `http://m-test45.shein.co.in/transit?deeplink=${encodeURIComponent(deepLink)}&journey_name=${JOURNEY_NAME}` // 测试，使用测试当前发的分支

      const mapSucc = await this.mapLink(shortLink, transitPageLink)

      let link = ''
      if (mapSucc) {
        link = shortLink
      } else {
        link = transitPageLink
      }

      return Promise.resolve({ link, deepLink })
    },
    async mapLink(shortLink, transitPageLink) {
      if (this.req) {
        this.req.abort()
      }

      this.req = new SchttpAbortCon()

      try {
        const res = await schttp({
          method: 'post',
          url: '/api/common/mapLink/create',
          data: {
            key: shortLink,
            value: transitPageLink,
          },
          signal: this.req.signal,
        })
        return `${res.code}` === 0
      } catch (e) {
        return false
      } finally {
        this.req = null
      }
    },
    async exposeAnalysis() {
      const params = {
        activity_name: 'expose_popup_m_app_journey',
        activity_param: {
          ...(await this.getCommonParams()),
          is_installed: 0,
        },
      }
      this.saSend(params)
    },
    async clickAnalysis(link, deepLink) {
      const params = {
        activity_name: 'click_M_APP_Banner_Button',
        activity_param: {
          ...(await this.getCommonParams()),
          paste_key: link,
          deeplink: deepLink,
        },
      }
      this.saSend(params)
    },
    async getCommonParams() {
      const { value } = await deviceFingerprint.get() || {}
      return {
        finger_print_key: value,
        journey_id: JOURNEY_NAME,
      }
    },
    saSend(params) {
      const { SA_REPORT_URL } = gbCommonInfo.REPORT_URL
      sa('send', params)
      sa('send', params, {
        _url: SA_REPORT_URL + '/mdmpMarketingWebReport',
      })
    },
  },
}
</script>

<style lang="less">
.app-discount {
  .flexbox();
  .space-between();
  .align-center();
  width: 100%;
  height: 40px;
  padding: 0 16px;
  margin-top: 10px;
  cursor: pointer;
  background-color: @sui_color_promo_bg;
  color: @sui_color_promo;
  .sui_icon_phone_16px,
  .icon-app-only {
    display: inline-block;
    font-size: 16px;
  }
  .arrow {
    display: inline-block;
    height: 12px;
  }
}
.app-discount-popover {
  &__goods-info {
    .flexbox();
    margin-bottom: 16px;
    > img {
      width: 50px;
      height: 66px;
      .margin-r(12px);
    }
    &_right {
      .flexbox();
      flex-direction: column;
      .space-between();
    }
    &_top {
      .flexbox();
      .align-center();
    }
    &_price {
      font-size: 18px;
      font-weight: bold;
      color: #222;
      font-family: 'Arial Black';
      .margin-r(4px);
    }
    &_discountPrice {
      color: @sui_color_discount;
    }
    &_retailPrice {
      font-size: 14px;
      color: #959595;
      text-decoration: line-through;
      .margin-r(5px);
    }
    &_flash {
      background: #ffd665;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
    }
    &_discount {
      background: #222;
      color: #fff;
      padding: 0 8px;
      height: 18px;
      line-height: 18px;
      /* rw:begin*/
      font-family: "Adieu";
      font-weight: 400;
      background-color: @sui_color_main;
    }
    &_appDiscount {
      height: 24px;
      line-height: 24px;
      padding: 0 6px;
      margin-bottom: 4px;
      white-space: nowrap;
      background-color: @sui_color_promo_bg;
      color: @sui_color_promo;
    }
  }
  &__qr-code {
    > img {
      width: 276px;
      height: 276px;
      border: 1px solid #e5e5e5;
      padding: 23px;
      margin-bottom: 8px;
    }
    &_ctn {
      width: 276px;
      height: 276px;
      border: 1px solid #e5e5e5;
      padding: 22px;
      margin: 0 auto 8px;
    }
    &_desc {
      max-width: 276px;
      font-size: 14px;
      color: #767676;
      margin: 0 auto;
      text-align: center;
    }
  }
}
</style>
